<template>
	<div>
  	<Breadcrumbs :items="crumbs" />
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <div class="fullscreen-bg vid-back" >
        <div class="overlay">
          <v-container>
            <div class="display-3">
              <span class="thin">Fan</span>
            </div><br />
            <div class="display-4">
              <span class="thick">Experiences</span>
            </div>
          </v-container>
        </div>
        <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
          <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2FCoachellaVideo2.mp4?alt=media&token=25952651-bc97-4821-9a33-fd5d7608e77a" type="video/mp4">
          <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/media.io_CoachellaVideo2.webm?alt=media&token=804aab3a-20f2-4f23-bd3a-d6c28cb0c271" type="video/webm">
          <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/media.io_CoachellaVideo2.ogv?alt=media&token=6d06ba23-75a9-4c77-bcce-7d766ac28c67" type="video/ogv">
          Your browser does not support the video tag.s
        </video>
        
      </div>
    </div>

    <div v-if="$vuetify.breakpoint.smAndDown">
      <div class="fullscreen-bg vid-back" >
        <div class="overlay">
          <v-container>
            <div class="display-3">
              <span class="thin">Fan</span>
            </div><br />
            <div class="display-4">
              <span class="thick">Experiences</span>
            </div>
          </v-container>
        </div>
        <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
          <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2FCoachellaVideo2small.mp4?alt=media&token=886c4199-4080-43a2-84c8-b5102fed08ae" type="video/mp4">
        </video>
        
      </div>
    </div>
  
    
    <div class="white">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="headline">Connect, engage and transform a concert, arena, or stadium experience.</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">Energize and excite the audience</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Share instant promotions</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Understand fan trends and activity</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Unlimited opportunities for fan interaction</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Targeted demographics provide enhanced experience</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Provide on-the-spot sales incentives</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="white">
    <v-container grid-list-xs fluid pa-0>
      <v-layout wrap>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/2guys.png?alt=media&token=15883a04-e86a-429b-aae1-a9ba561f0a5a"
              :aspect-ratio="1"
            ></v-img>
          </v-card>
        </v-flex>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/3.png?alt=media&token=ca40e2b6-36b5-4eb9-ab92-ea35df58c8b6"
              :aspect-ratio="1"
            ></v-img>
          </v-card>
        </v-flex>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/blueguy.png?alt=media&token=5e82eaa7-8395-4085-9732-0d0d975a3898"
              :aspect-ratio="1"
            ></v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    </div>
    <section>
    <div class="fullscreen-bg-adjust" > 
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2Ftaylorsmall.mp4?alt=media&token=5a4ea8ce-35f5-46dd-ac5c-8d7295a557a7">
      </video>
    </div>
     </section>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Industries',
          disabled: false,
          exact: true,
          to: '/industries'
        },
        {
          text: 'Fan Engagement',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>